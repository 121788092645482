// extracted by mini-css-extract-plugin
export var category = "post-module--category--4VXZJ";
export var post = "post-module--post--K0oOa";
export var post__content = "post-module--post__content--XvGfQ";
export var post__desc = "post-module--post__desc--8Uxlu";
export var post__details = "post-module--post__details---YIYM";
export var post__info = "post-module--post__info--xpdrz";
export var post__poster = "post-module--post__poster--jo8wa";
export var post__title = "post-module--post__title--UJfST";
export var related = "post-module--related--GpZcP";
export var related__category = "post-module--related__category--zVRtf";
export var related__desc = "post-module--related__desc--a15hV";
export var related__details = "post-module--related__details--jMHYj";
export var related__post = "post-module--related__post--T0qxS";
export var related__poster = "post-module--related__poster--F8n9x";
export var related__title = "post-module--related__title--4Gb32";
export var related__title_top = "post-module--related__title_top--ltZXT";
export var related__wrapper = "post-module--related__wrapper--uYPHO";
export var share = "post-module--share---mB7p";